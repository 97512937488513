import {Box, Flash, Button, Text, Link} from '@primer/react'
import {useCurrentRepository} from '@github-ui/current-repository'
import {migrateImmutableActionsPath, viewActionsPath} from '@github-ui/paths'
import {useRef, useState} from 'react'
import {ImmutableActionsMigrationDialog} from './ImmutableActionsMigrationDialog'
import {ImmutableActionsHasUnmigratableTagsDialog} from './ImmutableActionsHasUnmigratableTagsDialog'
import {isFullSemver} from '@github-ui/semver-util'

interface ImmutableActionsMigrationBannerProps {
  releaseTags: string[]
}

export function ImmutableActionsMigrationBanner({releaseTags}: ImmutableActionsMigrationBannerProps) {
  const [isMigrationDialogOpen, setMigrationDialogOpen] = useState(false)
  const [isHasUnmigratableTagsDialogOpen, setHasUnmigratableTagsDialogOpen] = useState(false)
  // TODO what if already in progress?
  const [isMigrationInProgress, setIsMigrationInProgress] = useState(false)
  const returnFocusRef = useRef(null)
  const repo = useCurrentRepository()
  const migratePath = migrateImmutableActionsPath({owner: repo.ownerLogin, repo: repo.name})
  const actionsPath = viewActionsPath({owner: repo.ownerLogin, repo: repo.name})
  // The query that populates releaseTags is limited to 1000 results
  // TODO update UI to indicate results were truncated, limit number of tags shown
  const nonSemverTags = releaseTags.filter(tag => !isFullSemver(tag))

  return (
    <Flash>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
        {isMigrationInProgress ? (
          <>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                Your Action is being migrated to an immutable package version.
              </Box>
            </Box>
            <div>
              <Button as="a" href={actionsPath} data-testid="ia-migration-progress-button">
                View progress
              </Button>
            </div>
          </>
        ) : (
          <>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Text sx={{fontWeight: 600}}>Make your GitHub Action Immutable</Text>
              </Box>
              <Text sx={{fontSize: 0}}>
                Upgrade to a new more secure and more reliable way of releasing and referencing GitHub Actions by
                recreating your existing tags as immutable package versions.{' '}
                <Link inline target="_blank" href="https://docs.github.com/actions">
                  Learn more about immutable actions.
                </Link>
              </Text>
            </Box>
            <div>
              <Button
                ref={returnFocusRef}
                data-testid="ia-migration-button-banner"
                onClick={() => {
                  if (nonSemverTags.length > 0) {
                    setHasUnmigratableTagsDialogOpen(true)
                  } else {
                    setMigrationDialogOpen(true)
                  }
                }}
              >
                Create immutable action
              </Button>
            </div>
          </>
        )}
      </Box>
      {isHasUnmigratableTagsDialogOpen && (
        <ImmutableActionsHasUnmigratableTagsDialog
          releaseTags={releaseTags}
          nonSemverTags={nonSemverTags}
          setMigrationDialogOpen={setMigrationDialogOpen}
          setHasUnmigratableTagsDialogOpen={setHasUnmigratableTagsDialogOpen}
        />
      )}
      {isMigrationDialogOpen && (
        <ImmutableActionsMigrationDialog
          repoName={repo.name}
          releaseTags={releaseTags}
          nonSemverTags={nonSemverTags}
          migratePath={migratePath}
          setMigrationDialogOpen={setMigrationDialogOpen}
          setHasUnmigratableTagsDialogOpen={setHasUnmigratableTagsDialogOpen}
          setIsMigrationInProgress={setIsMigrationInProgress}
        />
      )}
    </Flash>
  )
}

try{ ImmutableActionsMigrationBanner.displayName ||= 'ImmutableActionsMigrationBanner' } catch {}